@use "../../theme/base";

// for card ....
.card {
  width: 345px;
  margin: 3px;
  border: 1px solid silver;
}

.action {
  display: "flex";
  justify-content: "center";
}

.calendar-card {
  width: 100%;
  border: 1px solid base.$primary-light;
  display: flex;
  flex-direction: column;
  padding: 2px;
  align-items: center;
  height: 100%;
  background-color: white;
}
.day-card {
  width: 100%;
  border: 0px solid base.$secondary-light;
  display: flex;
  flex-direction: column;
  padding: 2px;
  align-items: center;
  height: 100%;
  background-color: white;
  font-size: 9pt;
}

.schedule-card {
  width: 100%;
  border: 1px solid base.$primary-light;
  display: flex;
  flex-direction: column;
  padding: 2px;
  align-items: center;
  height: auto;
  background-color: white;
}

.schedule-item-card {
  position: absolute;
  border: 10px solid blue;
  top: 500px;
  left: 5000;
  padding: 0;
  margin: 0;
  z-index: 100;
  overflow: hidden;
}
